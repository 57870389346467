/* Account Overrides */
.Login {
    h1 {
        text-transform: capitalize;
    }

    .NewCustWrap {
        max-width: none;

        @media (min-width: 768px) {
            max-width: 230px;
        }
    }

    .passButtons .ImgButWrap a {
        padding: 14px 32px;
        border-radius: 4px;

        &.EmailRequestSubmit,
        &#PasswordResetSubmit {
            background-color: var(--site-cta-background);
            color: #fff;

            &:hover {
                background-color: var(--site-cta-background-hover);
            }
        }
    }

    .innerBorder {
        margin: 0;
        border: 1px solid var(--site-dark-grey);

        @media (max-width: 767px) {
            margin: 0 15px 15px;
        }

        h2 {
            font-weight: var(--font-weight-bold);
        }

        input,
        select {
            border: 1px solid var(--site-dark-grey);
        }
    }

    .accountIcons p {
        font-size: var(--body-font-size);

        &.strongTitle {
            font-size: var(--subtitle-font-size);
            font-weight: var(--font-weight-bold);
        }
    }

    .RegistrationButtons {
        @media (max-width: 767px) {
            padding: 0;
        }

        .submitBut {
            text-align: left;

            a {
                border-radius: var(--padding-unit);
                font-size: calc(var(--body-font-size) + 2);
                font-weight: var(--font-weight-bold);
                letter-spacing: 0.05em;
                padding: 19px 16px;

                &::after {
                    display: none;
                }

                &:hover {
                    background-color: var(--site-cta-background-hover);
                    color: var(--site-white);
                }

                span {
                    text-shadow: none;
                }
            }
        }

        .cancelBut {
            display: none;
        }

        .headerPass h1 {
            @media (max-width: 767px) {
                text-align: center;
            }
        }
    }
}

.Profile {
    .ImgButWrap a {
        border-radius: var(--padding-unit);

        &:hover {
            background-color: var(--site-cta-background-hover);
        }
    }

    #accountMenu {
        border: 1px solid var(--site-dark-grey);

        .sidebar-nav li a,
        #accountMenu .sidebar-nav li.active a {
            color: var(--site-black);
        }

        .sidebar-nav li.LastOptionAcc {
            border-bottom: 1px solid var(--site-dark-grey);
        }
    }

    #AddressFormWrapper {
        input {
            border-radius: var(--padding-unit);
        }
    }

    #AddressBookModal {
        .modal-header {
            border-bottom: 1px solid var(--site-dark-grey);

            .text-center .header-text {
                font-weight: var(--font-weight-bold);
                text-transform: capitalize;
            }
        }

        #addressForm label {
            text-transform: capitalize;
        }

        .addressbook-positive-contain a {
            background-color: var(--site-cta-background);
            border: 1px solid var(--site-cta-background);
            color: var(--site-white);
            width: 100%;
            border-radius: var(--padding-unit);
            font-size: var(--body-font-size);
            font-weight: var(--font-weight-bold);
            letter-spacing: 0.05em;

            &:hover {
                background-color: var(--site-cta-background-hover);
            }
        }
    }

    .ProfileWrap {
        p,
        .TotalWrap,
        .safeText {
            font-size: var(--body-font-size);
        }

        &.cprefs section {
            .innerPref {
                border: 1px solid var(--site-dark-grey);
            }
            &.cprefsBox {
                ul {
                    border: 1px solid var(--site-dark-grey);

                    label {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .cprefsCategories li input[type="checkbox"]:checked {
            accent-color: #cc3083;
            accent-color: var(--site-accent);
        }

        .dnnFormItem input,
        .form-control {
            border: 1px solid var(--site-dark-grey);
        }
    }
}

.accountWrapper .noPad {
    .accountBox,
    .logoutBox {
        border: 1px solid var(--site-dark-grey);
    }
}

.NewCustWrap .ImgButWrap,
.ProfileWrap .saveBtn #btnSaveChanges {
    border-radius: var(--padding-unit);

    a {
        height: 44px;
        padding: 14px 10px;

        span {
            font-weight: var(--font-weight-bold);
            letter-spacing: 0.06em;
        }
    }

    &:hover {
        background-color: var(--site-cta-background-hover);
        border: 1px solid var(--site-cta-background-hover);
        color: var(--site-white);
    }
}
