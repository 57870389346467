#Body #onetrust-consent-sdk {
    #onetrust-banner-sdk {
        background-color: var(--site-white);
    }
    a:focus-visible {
        outline: var(--focus-outline) !important;
        outline-offset: var(--focus-outline-offset);
        opacity: 1;
    }
    #onetrust-policy-title,
    #onetrust-policy-text,
    .ot-b-addl-desc,
    .ot-dpd-desc,
    .ot-dpd-title,
    #onetrust-policy-text *:not(.onetrust-vendors-list-handler),
    .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
    #banner-options *,
    .ot-cat-header,
    .ot-optout-signal {
        color: var(--site-black);
    }
    #onetrust-pc-sdk {
        background-color: var(--site-white);
        .ot-pc-footer {
            background-color: var(--site-white);
            &::before {
                background: none;
            }
        }
        h3,
        h4,
        h5,
        h6,
        p,
        #ot-ven-lst .ot-ven-opts p,
        #ot-pc-desc,
        #ot-pc-title,
        .ot-li-title,
        .ot-sel-all-hdr span,
        #ot-host-lst .ot-host-info,
        #ot-fltr-modal #modal-header,
        .ot-checkbox label span,
        #ot-pc-lst #ot-sel-blk p,
        #ot-pc-lst #ot-lst-title h3,
        #ot-pc-lst .back-btn-handler p,
        #ot-pc-lst .ot-ven-name,
        #ot-pc-lst #ot-ven-lst .consent-category,
        .ot-leg-btn-container .ot-inactive-leg-btn,
        .ot-label-status,
        .ot-chkbox label span,
        #clear-filters-handler,
        .ot-optout-signal,
        .ot-plus-minus span {
            color: var(--site-black);
        }
        .ot-cat-grp {
            border-top-color: var(--site-black);
        }
        .ot-switch-nob,
        .ot-accordion-layout .ot-always-active:before {
            background: var(--site-light-grey);
            border: 1px solid var(--site-mid-grey);
        }
        .ot-switch-nob:before,
        .ot-accordion-layout .ot-always-active:after {
            background-color: var(--site-mid-grey);
        }
        .ot-tgl input:checked + .ot-switch .ot-switch-nob,
        .ot-accordion-layout .ot-always-active:before {
            background: var(--site-light-grey);
            border: 1px solid var(--site-accent);
        }
        .ot-tgl input:checked + .ot-switch .ot-switch-nob:before,
        .ot-accordion-layout .ot-always-active:after {
            background-color: var(--site-accent);
            border-color: var(--site-accent);
        }
        .ot-acc-grpcntr.ot-acc-txt,
        .ot-acc-txt .ot-subgrp-tgl .ot-switch.ot-toggle {
            background-color: var(--site-light-grey);
        }
        input:focus-visible + .ot-switch {
            outline: var(--focus-outline) !important;
            outline-offset: var(--focus-outline-offset);
        }
    }
    #onetrust-pc-sdk button,
    #onetrust-banner-sdk #onetrust-button-group button {
        text-transform: lowercase;
        font-size: var(--subtitle-font-size);
        &::first-letter {
            text-transform: uppercase;
        }
        &:focus-visible {
            outline: var(--focus-outline) !important;
            outline-offset: var(--focus-outline-offset);
            opacity: 1;
        }
    }
    #onetrust-button-group #onetrust-pc-btn-handler,
    #onetrust-button-group #onetrust-pc-btn-handler.cookie-setting-link,
    #onetrust-pc-sdk button.ot-pc-refuse-all-handler {
        background-color: var(--site-white) !important;
        border: 1px solid var(--site-black) !important;
        border-radius: var(--site-cta-border-radius);
        color: var(--site-black) !important;
        padding: var(--site-cta-padding);
    }
    #onetrust-button-group button,
    #onetrust-pc-sdk button.save-preference-btn-handler,
    #onetrust-pc-sdk button#accept-recommended-btn-handler {
        background-color: var(--site-cta-background) !important;
        color: var(--site-white) !important;
        padding: var(--site-cta-padding);
        border-radius: var(--site-cta-border-radius);
        &:hover {
            background-color: var(--site-cta-background-hover) !important;
            opacity: 1;
        }
        &:focus-visible {
            outline: var(--focus-outline) !important;
            outline-offset: var(--focus-outline-offset);
            opacity: 1;
        }
    }
}
