@font-face {
    font-family: Montserrat;
    src: url("/Fonts/Montserrat-Regular.woff2") format("woff2");
}

@font-face {
    font-family: Montserrat;
    src: url("/Fonts/Montserrat-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
