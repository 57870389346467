:root {
    /* Search autocomplete spacer */
    --autocomplete-top: 4px;
    /* Primary colours */
    --site-white: #fff;
    --site-black: #000;
    --site-dark-grey: #5e5e5e;
    --site-mid-grey: #c4c4c4;
    --site-light-grey: #e7eaec;
    --site-lightest-grey: #f8f8f8;
    --site-outlet: #a63b00;
    /* Secondary colours */
    --site-accent: #cc3083;
    --site-accent-secondary: #b82b76;
    --site-green: #86ffa1;
    --site-dark-green: #73ca87;
    --site-red: #ed0000;
    --padding-unit: 4px;
    --header-font-size: 18px;
    --body-font-size: 14px;
    --subtitle-font-size: 16px;
    --font-weight-regular: normal;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;
    --large-container-max-width: 1600px;
    --default-container-max-width: 1400px;
    --spritesheet: url("/images/core/game-icons-1.svg");
    --default-font-family: Montserrat, arial, helvetica, sans-serif;
    --font-family-bold: var(--default-font-family);
    --brand-font: var(--default-font-family);
    --default-letter-spacing: 0;
    --site-footer-border: var(--site-accent);
    --site-count-bubble: var(--site-accent);
    --site-count-bubble-text-color: var(--site-white);
    --font-line-height: 18px;
    /* Icon Styling */
    --site-icon-stroke: var(--site-black);
    --site-icon-hover-stroke: var(--site-black);
    --site-icon-hover-fill: transparent;
    /* CTA styling */
    --site-cta-background: var(--site-accent);
    --site-cta-text: var(--site-white);
    --site-cta-background-hover: var(--site-accent-secondary);
    --site-cta-text-hover: var(--site-white);
    --site-cta-padding: 20px 15px;
    --site-cta-transactional-background: var(--site-cta-background);
    --site-cta-transactional-text: var(--site-cta-text);
    --site-cta-transactional-background-hover: var(--site-cta-background-hover);
    --site-cta-transactional-text-hover: var(--site-cta-text-hover);
    --site-cta-disabled-background: var(--site-mid-grey);
    /* Elevated Cart Variables */
    --cart-font-family: var(--default-font-family);
    --cart-font-family-bold: var(--default-font-family);
    --cart-font-family-italic: var(--default-font-family);
    --cart-sticky-element-default-top-value: 142px;
    --cart-details-margin-top-value: 32px;
    --cart-heading-font-style: normal;
    --cart-button-background-color: var(--site-black);
    --cart-button-hover-color: var(--site-dark-grey);
    --cart-button-font-style: normal;
    --cart-button-font-weight: var(--font-weight-regular);
    --cart-border-radius: 0;
    --cart-button-transactional-background-color: var(--site-black);
    --cart-button-transactional-hover-background-color: var(--site-dark-grey);
    --cart-button-transactional-font-color: var(--site-white);
    --cart-total-font-weight: var(--font-weight-bold);
    --cart-error-font-color: var(--site-red);
    --cart-discount-font-color: var(--site-red);
    --cart-error-background-color: hsl(355.13deg 93.02% 98%);
    --product-line-item-line-age-restriction-color: var(--site-red);
    --product-line-item-price-font-weight: normal;
    --product-line-item-line-error-color: var(--site-red);
    --product-line-item-line-personalisation-color: var(--site-accent);
    --product-line-item-line-pre-order-color: var(--site-accent);
    --product-line-item-font-family-bold: var(--default-font-family);
    --product-line-item-discount-text-color: var(--site-red);
    --product-line-item-border-radius: 4px;
    /* Wishlist Variables */
    --wishlist-summary-header-font-style: normal;
    --wishlist-header-font-style: normal;
    --wishlist-tick-background-color: var(--site-accent);
    /* Checkout Variables */
    --checkout-my-bag-colour: var(--site-accent);
    --checkout-active-section-colour: var(--site-accent);
    /* Focus Variables */
    --focus-outline: 1px dashed var(--site-black);
    --focus-outline-offset: 5px;
    /* Override Variables */
    --site-cta-border-radius: 4px;
    --swiper-preloader-color: var(--site-accent);
    --site-membership-badge-background-colour: var(--site-accent);
    --site-plp-sash-height: 19px;
    --personalisation-modal-cta-background-color: var(--site-cta-background);
    --personalisation-modal-cta-color: var(--site-cta-text);
    --personalisation-modal-cta-border-color: transparent;
    --personalisation-modal-cta-hover-background-color: var(--site-cta-background-hover);
    --personalisation-modal-cta-hover-color: var(--site-cta-text-hover);
    --personalisation-modal-cta-hover-border-color: transparent;
    --personalisation-modal-cta-font-weight: var(--font-weight-bold);
    --personalisation-modal-label-font-weight: var(--font-weight-regular);
    --personalisation-loader-color: var(--site-accent);
    --sitewide-banner-height: 50px;
    --sitewide-banner-border: 1px solid var(--site-mid-grey);

    --pdp-two-image-carousel-thumbnail-border-colour: transparent;
    --pdp-two-image-carousel-thumbnail-active-border-colour: var(--site-accent);
    --pdp-two-image-carousel-thumbnail-border-radius: 4px;
    --pdp-two-image-carousel-icon-colour: var(--site-accent);
    --pdp-two-image-carousel-icon-background-colour: var(--site-white);

    --product-filters-toggle-hover-border-color: var(--site-accent);
    --product-filters-toggle-active-border-color: var(--site-accent);
    --product-filters-toggle-filter-count-color: var(--site-accent);

    --esd-loader-color: var(--site-accent);
}
