/* PLP Overrides */

.NextLink.disablePaginationArrow,
.PrevLink.disablePaginationArrow {
    pointer-events: none;
    cursor: default;
    opacity: 0.2;
}

#showFilterButtonDiv .btn.btn-default.showFilterButton {
    display: block;
    background-color: var(--site-cta-background);
    color: var(--site-cta-text);
    border-radius: var(--site-cta-border-radius);
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }

    &:hover {
        background-color: var(--site-cta-background-hover);
        color: var(--site-cta-text-hover);
    }
}

#productlistcontainer .productimage.s-productthumbimage:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.13333);
    opacity: 0.3;
    pointer-events: none;
    transition: 0.3s ease-in-out;
}

#productlistcontainer .s-productthumbbox:hover .productimage.s-productthumbimage:after {
    opacity: 0.65;
}

.Browse .s-productscontainer2 .s-productthumbimage .ProductImageList img {
    padding: calc(var(--padding-unit) * 3) !important;
}

.s-productthumbbox .sashContainer {
    height: var(--site-plp-sash-height);
}

[data-sash-touchpoint="PLP"] {
    position: static;
    flex-direction: row;
    width: 100%;

    .product-sash {
        flex: 1 1 auto;
        padding: 5px;
        font-size: 9px;
        font-weight: var(--font-weight-bold);
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (min-width: 1022px) {
            line-height: 9px;
        }
    }

    @media (max-width: 1639px) {
        position: relative;
        top: 0;
        gap: 0;
        width: 100%;

        .product-sash {
            flex: 0 0 100%;
            width: 100%;
            position: absolute;
        }

        .product-sash:nth-of-type(1) {
            animation-delay: 0s;
        }

        .product-sash:nth-of-type(2) {
            animation-delay: 4s !important;
        }

        .product-sash:first-child:nth-last-child(1),
        .product-sash:first-child:nth-last-child(1) ~ .product-sash {
            animation: 1s autoplay1sash infinite ease;
        }

        @keyframes autoplay1sash {
            0% {
                opacity: 1;
                z-index: 1;
            }

            100% {
                opacity: 1;
                z-index: 1;
            }
        }

        .product-sash:first-child:nth-last-child(2),
        .product-sash:first-child:nth-last-child(2) ~ .product-sash {
            animation: 8s autoplay2sashes infinite ease;
        }

        @keyframes autoplay2sashes {
            0% {
                opacity: 0;
                z-index: -1;
            }

            2% {
                opacity: 1;
                z-index: 1;
            }

            50% {
                opacity: 1;
                z-index: 1;
            }

            52% {
                opacity: 0;
                z-index: -1;
            }

            100% {
                opacity: 0;
                z-index: -1;
            }
        }
    }
}

.Browse .s-productscontainer2 {
    [data-sash-touchpoint="PLP"] .product-sash {
        z-index: 1;
    }

    &.plp-products-container li:hover .s-productthumbbox {
        box-shadow: none;
    }

    .TextSizeWrap .s-productthumbtext {
        display: grid !important;
        grid-template-columns: 1fr 40px;
        grid-template-rows: 1fr;
        column-gap: 5px;
        grid-template-areas:
            "productName quickArea"
            "productAttributes quickArea"
            "frasersPlus quickArea"
            "productPrice quickArea";

        @media (max-width: 767px) {
            display: block;
            grid-template-columns: 1fr;
            grid-template-areas:
                "productName"
                "productAttributes"
                "frasersPlus "
                "productPrice"
                "quickArea";
        }

        .s-producttext-top-wrapper {
            grid-area: productName;

            .productdescriptionbrand,
            .productdescriptionname {
                font-size: calc(var(--body-font-size) + 1px);
                line-height: 18px;
            }

            .productdescriptionbrand {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .productdescriptionname {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }

        .s-productthumb-extra {
            grid-area: productAttributes;
        }

        .member-pricing {
            grid-area: frasersPlus;
            font-size: calc(var(--body-font-size) + 1px);

            p {
                font-size: 1em;
                font-weight: var(--font-weight-bold);
                text-transform: none;
            }
        }

        .s-producttext-price {
            grid-area: productPrice;

            .s-largered .curprice {
                font-size: calc(var(--body-font-size) + 1px);
            }
        }

        .wishlist-quickbuy-wrapper {
            grid-area: quickArea;
            padding-top: 12px;
        }
    }

    .wishlist-quickbuy-wrapper {
        display: flex;
        flex-direction: column;
        gap: 11px;

        .hotspotbuy.hotspotquickbuy,
        .hotspotbuy.hotspotwishlist {
            position: static;
            display: block !important;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: var(--site-lightest-grey);
        }

        .hotspotbuy.hotspotwishlist {
            .wishListSVG {
                padding: 8px;
            }

            &.addedWishList .wishListSVG .global-icon svg path {
                stroke: var(--site-black);
                fill: var(--site-black);
            }

            &.addedWishList .wishListSVG .global-icon svg .icon-hover {
                fill: var(--site-white);
            }
        }

        .hotspotbuy.hotspotquickbuy {
            .QuickLookIcon {
                width: 40px;
                height: 40px;
                background-color: transparent;
            }

            .QuickLookIcon svg {
                width: 24px;
            }
        }
    }
}

.Browse .s-productscontainer2:not(.swiper-wrapper) {
    row-gap: calc(var(--padding-unit) * 12);
    column-gap: calc(var(--padding-unit) * 4);
}

.AltProdDet #productDetails .WishListContain .sAddToWishListWrapper a {
    &:hover {
        svg path {
            stroke: var(--site-black);
        }

        svg .icon-hover {
            stroke: var(--site-black);
        }
    }

    &.addedWishList svg polyline {
        stroke: var(--site-white);
    }
}

.AltProdDet #productDetails #divShareButton span.global-icon.global-icon-share:hover svg {
    path {
        stroke: var(--site-black);

        &.arrow {
            stroke: transparent;
            fill: var(--site-black);
        }
    }
}

.Browse.sdlProdList #lblCategoryCopy {
    padding: 0;

    .catTemplate:not(.ImgCatVersion) {
        .catDesc {
            padding: 0 40px;

            @media (min-width: 1640px) {
                padding: 0;
            }

            @media (max-width: 1021px) {
                padding: 0 15px;
            }
        }

        @media (min-width: 1022px) {
            justify-content: flex-start;
        }

        .siblingLinks {
            span a {
                border-radius: var(--padding-unit);
            }
        }
    }

    .catTemplate .siblingLinks,
    .topheadbox .siblingLinks {
        padding: calc(var(--padding-unit) * 4.5) 40px 0;

        @media (max-width: 1021px) {
            padding: calc(var(--padding-unit) * 4.5) 15px 0;
        }

        a {
            text-transform: none;
        }

        span:first-of-type {
            margin-left: 0;
        }
    }
}

#hotspotModal .PinWrapText {
    .hsProductName {
        order: 0;

        h2 {
            color: var(--site-black);
        }
    }

    .member-price {
        font-size: calc(var(--body-font-size) + 1px);

        &[data-member-price-scheme="FRAS"] {
            padding: 0;
        }
    }

    #hsPriceWrapper #hsRefPrice {
        font-weight: var(--font-weight-regular);
    }

    .hsVariantButtons .hsSizeButtonli {
        & a {
            font-size: var(--body-font-size);
        }

        & a,
        & a:hover,
        &.hsVariantHighlight a,
        &.hsVariantHighlight a:hover {
            font-weight: var(--font-weight-regular);
        }

        & a:hover {
            border-color: var(--site-accent);
        }

        &.hsVariantHighlight a {
            border-color: var(--site-accent) !important;
            color: var(--site-accent);
        }
    }

    .hsColourDesc .ColourLabel,
    .hsSizeLabel {
        font-weight: var(--font-weight-bold);
    }
}

.sdDetails #productDetails .productVariantContainer #availableFromContainer {
    background-color: #000;
    color: #fff;
    padding: 16px 0;
    text-align: center;
    border-radius: var(--padding-unit);
}

#hotspotModal .PinWrapText #hsViewProduct a {
    border-radius: 0;
}

.flexFiltersContainer .mobSortFilter .MobSortSelector:has(.productFilterTitleBox:not(.FilterClosed)) {
    border-color: var(--site-accent);
    border-bottom-color: var(--site-white);

    .productFilterList {
        border-color: var(--site-accent);
    }
}

.flexFiltersContainer .mobSortFilter #filterByMob,
.flexFiltersContainer .mobSortFilter .MobSortSelector {
    border-radius: var(--site-cta-border-radius);
}

#PriceFilterTextEntry #PriceFilterTextEntryApply {
    background-color: var(--site-accent);
    color: var(--site-white);
    border: none;

    &:hover {
        background-color: var(--site-accent-secondary);
    }
}

#Body.Browse #mobFilterControls #mobappfltrs.mobFilterDisabled .textIconWrap {
    background-color: var(--site-cta-disabled-background) !important;
    border-color: var(--site-mid-grey);

    span {
        color: var(--site-black);
        font-weight: var(--font-weight-bold);
    }
}

#Body.Browse #mobFilterControls .textIconWrap span {
    font-weight: var(--font-weight-bold);
}

/* PLP Overrides - Filter Toggle */
#BodyWrap .FilterProductsTable {
    @media (min-width: 1022px) {
        display: grid;
        grid-template-columns: 220px 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "productFilters productList";
        transition: grid-template-columns 100ms ease-in-out;

        #FilterContainer {
            grid-area: productFilters;
            transition: transform 100ms ease-in-out;

            #innerfiltercontainer {
                width: 100% !important;

                .productFilters .productFilter.sort-by-in-filters input[type="radio"],
                .productFilterList .FilterName {
                    cursor: pointer;
                }
            }
        }

        #ProductContainer {
            grid-area: productList;
            background-color: var(--site-white);
        }

        .filtersHidden& {
            grid-template-columns: 0 1fr;

            #FilterContainer {
                transform: translateX(220px);
            }

            #ProductContainer {
                padding-left: 0;
            }
        }
    }
}

.Browse .productFilterList .SelectableFilter:hover::before,
.Browse #innerfiltercontainer .productFilters .productFilter.sort-by-in-filters input[type="radio"]:hover {
    border: 1px solid var(--site-accent);
    outline: none;
}
.Browse .productFilterList .SelectableFilter:active::before,
.Browse #innerfiltercontainer .productFilters .productFilter.sort-by-in-filters input[type="radio"]:active {
    outline: 1px solid var(--site-accent);
    outline-offset: 1px;
}

.sort-by-in-filters,
.FilterListItem {
    padding-left: 2px;
}
.Browse #FilterContainer #innerfiltercontainer {
    padding: 0;
}
.Browse #FilterContainer #mobFilterControls {
    padding: 20px !important;
}

.PageFromTo {
    border-bottom: 1px solid #ddd;
}
.Browse .s-productscontainer2 .TextSizeWrap .s-productthumbtext .s-producttext-top-wrapper .productdescriptionname {
    display: block;
    white-space: nowrap;
}
.sdlProdList .categorycopyd4 #lblCategoryCopy {
    font-size: 12px;
}

.flexFiltersContainer .mobSortFilter {
    flex-basis: 50%;
}

.mobFilterAppIcon {
    height: 22px;
    width: 22px;
    background-position: -367px -396px;
}

.MobSortSelector .productFilterTitleBox .glyphicon::before,
.mobFilterAppIcon {
    background-size: 758px 758px;
}

.MobSortSelector .productFilterTitleBox .glyphicon::before {
    background-position: -426px -362px;
}

.MobSortSelector .productFilterTitleBox.FilterClosed .glyphicon::before {
    background-position: -424px -365px;
}

.productFilterTitleBox .glyphicon::before,
.productFilterTitleBox.FilterClosed .glyphicon::before {
    height: 22px;
    width: 22px;
}

.flexFiltersContainer .mobSortFilter .MobSortSelector:has(.productFilterTitleBox:not(.FilterClosed)) {
    border-radius: 4px 4px 0 0;
}

.productFilterTitleBox.FilterClosed .FilterCollapseImage:before {
    transform: rotate(0deg);
}
.productFilterTitleBox .FilterCollapseImage:before {
    transform: rotate(180deg);
}
#BodyWrap .PageNumber span.PageSelector {
    color: var(--site-accent);
}
.swipePrevClick .PrevLink .disablePaginationArrow::before {
    color: var(--site-light-grey);
}
.sdlProdList.Browse #lblCategoryCopy {
    display: block !important;
}
.paginationWrapper .pagination .PageNumber a.PrevLink {
    margin-right: 2px;
}
.paginationWrapper .pagination .PageNumber a.NextLink {
    margin-left: 2px;
}
.pagination .PageNumber {
    float: none;
    text-align: center;
}
.topheadbox .PageFromTo .pppt,
.topheadbox .PageFromTo .pppt span {
    display: none;
}
.Responsive .mobDdClose {
    bottom: 50px;
}

@media (max-width: 1021px) {
    .Browse #innerfiltercontainer .productFilters {
        padding: 0 15px;
    }
    .sdlProdList .ChildCategoriesListWrapper {
        padding: 0 20px;
    }

    #Body.touchenabled .s-productthumbbox .hotspotquickbuy {
        display: block !important;
    }

    .PageFromTo {
        border-bottom: none;
    }
}
@media (max-width: 767px) {
    .Browse .s-productscontainer2 .wishlist-quickbuy-wrapper {
        flex-direction: row;
    }
}

@media (min-width: 1022px) {
    .HeadnCount .PageFromTo .pppt,
    .FiltersTitle {
        display: none;
    }
    #innerfiltercontainer #FiltersHeader {
        display: none;
    }
}

.gwpPromotionContainer .gwpPromotion {
    background-color: #fff;
    border: 0;
    border-top: 1px solid var(--site-mid-grey);

    div.promotionInfo {
        width: calc(100% - 120px);
        padding: 50px 5px 15px 10px;

        a {
            color: var(--site-black);
        }

        .promotionInfoTitle {
            position: absolute;
            top: 35px;
            left: 30px;
            width: auto;
            text-align: center;
            text-transform: capitalize;
            color: var(--site-cta-background);

            @media (min-width: 768px) {
                left: 15px;
            }

            &::before {
                background-image: url(https://www.flannels.com/images/core/flannels-20-sprite-sheet-v9.svg);
                background-repeat: no-repeat;
                content: "";
                display: inline-block;
                width: 25px;
                height: 25px;
                vertical-align: middle;
                background-repeat: no-repeat;
                background-position: -284px -230px;
                background-size: 405px 405px;
                margin-right: 5px;
            }
        }

        .promotionInfoDescription,
        .promotionInfoProduct {
            font-size: 14px;
        }

        .promotionInfoMore {
            display: none;
        }
    }

    img.promotionInfo {
        width: 100px;
        height: 100%;
        text-align: center;
        line-height: 70px;
        margin: 30px 5px 0;
        display: inline-block;
    }
}

.ProdDetails .pegiWrap {
    border-top: 1px solid var(--site-mid-grey);
}
